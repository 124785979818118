.carousel__indicators {
  display: flex;
  justify-content: center;

  $carousel-dot-size: 10px;

  &.carousel__indicators--dots {
    position: relative;
    bottom: 32px + $carousel-dot-size;
  }

  &.carousel__indicators--thumbnails {
    padding: 24px 16px;

    @include breakpoint(small down) {
      display: none;
    }
  }

  .carousel__indicator {
    margin: 0 4px;
    transition: background-color 0.3s,
      border-color 0.3s;

    &.carousel__indicator--dot {
      width: $carousel-dot-size;
      height: $carousel-dot-size;
      border-radius: $carousel-dot-size;
      background-color: $white;
      z-index: 1;
      cursor: pointer;
    }

    &.carousel__indicator--thumbnail {
      border: 3px solid transparent;
      cursor: pointer;
      height: 50px;
      width: 70px;
      background-size: cover;
      background-position: center;
    }

    &.carousel__indicator--selected {}
  }
}