.site-plan {
  // background-color: #f0f0f0;
  @include padding-top(40px, 32px);
  // @include padding-bottom(32px, 32px);

  .site-plan__sub-title {
    margin-top: 16px;
  }

  .site-plan__text {
    @include margin-top(32px, 24px);
  }

  .site-plan__content {
    @include margin-top(32px, 24px);

    div:first-child {
      margin-top: 0;
    }
  }

  &__map-container {
    text-align: center;

    max-height: 500px;
    margin: auto;

    img {
      margin: auto;
      max-height: 500px;
    }
  }

  &__list {
    margin-top: 32px;
    @include breakpoint(medium down) {
      margin: -16px;
      margin-top: 16px;
    }
  }

  &__row {
    display: flex;
    justify-content: space-between;
    min-height: 88px;
    align-items: center;

    @include breakpoint(medium down) {
      min-height: auto;
      flex-direction: column;
      align-items: flex-start;
      padding: 24px 16px;
    }
  }

  // &__details {}

  &__name {
    font-weight: 500;
  }

  &__info {
    margin-top: 8px;
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    font-weight: 500;

    @include breakpoint(medium down) {
      margin-top: 24px;
    }
  }

  &__floor-plan {
    margin: 0 8px;
    cursor: pointer;
    transition: color .2s;
    display: flex;
    align-items: center;

    &:hover {
      color: rgba($almost-black, 0.8)
    }

    .site-plan__download-button-image {
      padding-bottom: 4px;
    }

    .site-plan__download-button-text {
      margin-left: 8px;
    }
  }

  &__virtual-tour {
    margin-right: 8px;
    margin-left: 24px;
    cursor: pointer;
    transition: color .2s;
    display: flex;
    align-items: center;

    &:hover {
      color: rgba($almost-black, 0.8)
    }

    .site-plan__virtual-tour-image {
      padding-bottom: 4px;
    }

    .site-plan__virtual-tour-text {
      margin-left: 8px;
    }
  }

  .site-plan__line {
    background-color: rgba($almost-black, 0.2);
    height: 1px;
    width: 100%;
    margin: 0;
    padding: 0;
  }

}