#banner_main_portfolio {
    position: fixed;
    bottom: -100%;
    padding: 15px;
    height: 95px;
    background: #000;
    z-index: 1000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &.open {
        transition: 0.5s;
        width: 100%;
        bottom: 0px;
    }
    &.closing {
        transition: 1s;
        bottom: -100%;
    }
    img {
        max-height: 60px;
        left: 50px;
    }
    p {
        color: white;
        left: 50px;
        font-size: 14px;
    }
    a.button {
        background: transparent;
        color: white;

        font-size: 19px;
        cursor: pointer;
        cursor: hand;
        &:hover {
            cursor: pointer;
            cursor: hand;
            background: #fff;
            border: none;
            color: #000;
        }
    }
}
#js-disclaimer {
    display: none;
    &.open {
        z-index: 2000;
        background: #fff;
        border-radius: 5px;
        transition: 1s;
        position: fixed;
        top: 10%;
        left: 25%;
        display: block;
        width: 50%;

        &:before {
            content: "";
            background: rgba(0, 0, 0, 0.6);
            position: fixed;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            bottom: 0px;
            right: 0px;
            z-index: -1;
        }
        .disclaimer-content {
            padding: 28px;
            padding-top: 50px;
            padding-bottom: 80px;
            background: white;
            z-index: 2001;
            p {
                line-height: 23px;
            }
            i {
                position: absolute;
                top: 10px;
                right: 10px;
                cursor: pointer;
                cursor: hand;
            }
        }
    }
}
.fp-name {
    width: calc(100% - 200px);
}