.bounce {
  animation: bounce 2s;
}

@keyframes bounce {
  0%,
  25%,
  50%,
  75%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-6px);
  }
}
