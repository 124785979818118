button,
.button {
	font-size: 16px;
	font-weight: 500;
	display: inline-flex;
	align-items: center;
	svg + span{
		margin-left: 8px;
	}
	span + svg{
		margin-left: 8px;
	}
	&:disabled{
		background-color: black;
		opacity: .25;
	}
}
.button{
	box-shadow: inset 0 0 0 0 var(--accent-dark-color);
	transition: all ease-out 0.25s;
	position: relative;
	&:hover {
		transform: translateY(-2px);
		box-shadow: 
			inset 400px 0 0 0 var(--accent-dark-color),
			0 3px 8px rgba(33,33,33,.3);
	}
}

