
.hero.is-fullwidth{
  position: relative;
  height: 800px;
  text-align: center;
  padding: 0;
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  &.is-boxed{
    width: auto;
    max-width: 1208px;
    margin-left: auto;
    margin-right: auto;

    &.hero--align-left{
      .slick-arrow{
        @include breakpoint(large up){
          width: 40px;
          top: auto;
          bottom: 15px;
        }
      }
    }
    
  }

  @include breakpoint(small down) {
    height: 100vh;
  }

  .hero__background {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    z-index: 1;
  }

  .hero__content {
    width: 100%;
    max-width: 1208px; // .grid-container
    padding: 56px 16px;

    transform-origin: 50% 0%;
    transition: opacity 3000ms cubic-bezier(0.395, 0.425, 0.015, 1),transform 1000ms ease;
    @include breakpoint(medium down) {
    }

    @include breakpoint(small down) {
      padding-top: 24px;
      padding-bottom: 24px;
    }

    .is-loading &{
      transform: scale(0.7) rotateX(-15deg);
      opacity: 0;
    }
  }

  .hero__title {
    text-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
    font-weight: 600;
    line-height: 1;
    color: $white;
    margin-bottom: 16px;
  }

  .hero__sub-title {
    margin-top: 0;
    text-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
    color: $white;
  }

  .hero__button {
    margin-top: 40px;
    @include breakpoint(small down) {
      margin-top: 20px;
    }
  }
  .hero__slider{
    position: absolute;
    bottom: 0;
    top: 0;
      display: flex;
      flex: 0 0 100%;
    max-width: 100%;
    z-index: 0;
  }
  .slick-list{
    display: flex;
    flex-direction: column;
  }
  .slick-track{
    flex: 1;
  }
  .slider-item{
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100vw;
    img{display: none}
  }
  .slick-arrow{
    position: absolute;
    align-items: center;
    justify-content: center;

    // bottom: 16px;
    top: 50%;
    transform: translateY(-50%);
    // background-color: var(--accent-color);
    color: white;
    display: flex;
    width: 50px;
    height: 50px;
    z-index: 1;
    // border-radius: 50%;
    opacity: .8;
    transition: all 300ms ease;
    cursor: pointer;

    &:focus{outline: 0;}
    &:hover{
      opacity: 1;
      transform: translateY(calc(-50% - 2px));
    }
    @include breakpoint(large down){
      width: 40px;
      top: auto;
      bottom: 15px;
    }

  }
  .slick-prev{
    left: 16px;
    top: 50%;

    @include breakpoint(small down){
      bottom: 50%;
      left: 0;
    }
  }
  .slick-next{
    right: 16px;
    top: 50%;

    @include breakpoint(small down){
      bottom: 50%;
      right: 4px;
    }
  }


  .hero__gallery-button{
    position: absolute;
    bottom: 48px;
    right: calc(50vw - 604px + 16px);
    z-index: 3;
    @media (max-width: 1208px) {
      right: 16px;
    }
    @include breakpoint(small down) {
      bottom: 30px;
      span{
        display: none;
      }
    }
  }


  // ================== FULLSCREEN VARIATIONS ========================
  &.hero--align-left {
    text-align: left;
    @media (max-width: 1340px) { // this should break here :S
      .slick-arrow{
        top: auto;
        bottom: 15px;
      }
    //   .hero__content {
    //     // padding-left: 20px;
    //     // padding-right: 20px;
    //   }
    }
  }

  &.hero--opaque-diagonal-background {
    .hero__background {
      background: linear-gradient(65deg, rgba($almost-black, .7) 65%, rgba($white, 0) 65.1%);
    }

    .hero__title {
      width: 65%;
    }

    .hero__sub-title {
      width: 65%;
    }
    @include breakpoint(large down) {
      .slick-arrow{
        top: auto;
        bottom: 15px;
      }
    }
  }

  &.hero--opaque-center-background {
    .hero__background {
      height: auto;
      padding: 20px 0;
      background: rgba($almost-black, .7);
    }
  }

  &.hero--align-bottom {
    text-align: left;

    .hero__background {
      justify-content: flex-end;
      background: linear-gradient(0, $almost-black 0%, rgba($almost-black, 0) 60%);
      
      position: relative;
    }

    .hero__content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      // padding: 56px 45px;
    }

    .hero__text {
      flex-grow: 1;
    }

    .hero__title {
      width: 75%;
      margin-bottom: 0;
      @include breakpoint(small down) {
        width: 95%;
      }
    }

    .hero__sub-title {
      width: 75%;
      margin-bottom: 0;
      margin-top: 16px;
      @include breakpoint(small down) {
        width: 95%;
      }
    }

    .slick-prev{
      @include breakpoint(large down) {
        right: 65px;
        top: 50%;
      }
    }

    .hero__button {
      // margin: auto;
      margin-right: 0;
      margin-bottom: 8px;
    }

    @include breakpoint(small down) {

      // All CSS in here goes inside the media query
      .hero__content {
        flex-direction: column;
        // padding: 48px 16px;
      }

      .hero__button {
        // margin: auto;
        margin-left: 0;
        margin-top: 16px;
      }
    }

  }

  &.hero--has-call-out {
    position: relative;
    overflow-x: hidden;

    .hero__callout {
      // height: 56px;
      max-width: 400px;
      background: linear-gradient(70deg, rgba($white, 0) 15%, $white 15.5%);
      font-weight: bold;
      // top: 54px;
      top: 94px;
      right: 0;
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-top: 12px;
      padding-bottom: 12px;
      padding-right: 18px;
      padding-left: 88px;
      margin-left: 64px;
      text-align: right;
      // box-shadow: 0 2px 8px 0 rgba(10,11,15,0.5); // hehe :D
      filter: drop-shadow(2px 3px 5px rgba(0,0,0,0.3));
      z-index: 3;
      @include breakpoint(medium down) {
        font-size: 1rem;
      }
      @include breakpoint(small down) {
        font-size: .8rem;
        padding: 0.6em .6em .6em 1.9em;
        background: linear-gradient(73deg, rgba($white, 0) 10%, $white 10.5%);
      }
    }
  }



}

// VARIATION => TWO COLS
$hero-2cols-height: 500px;
// $hero-2cols-indent: calc(50vw - 604px + 12px);
$hero-2cols-indent: 30px;
.hero--2cols{
  max-width: 1208px;
  margin: 0 auto;
  min-height: $hero-2cols-height;
  display: flex;
  align-items: stretch;
  background-image: none !important;
  position: relative;
  
  @include breakpoint(medium down) {
    height: auto;
  }

  .hero__callout{
    display: none;
  }
  .hero__wrapper{
    display: flex;
    align-items: stretch;
    flex: 0 0 100%;
    max-width: 100%;
    
    @include breakpoint(medium down) {
      flex-wrap: wrap;
    }
  }
  .hero__cover{
    background-position: center;
    background-size: cover;
    background-color: var(--accent-color);
  }
  .hero__media{
    display: flex;
    flex: 0 0 50%;
    max-width: 50%;
    @include breakpoint(medium down) {
      flex: 0 0 100%;
      max-width: 100%;
      min-height: 60vw;
    }
  }
  .hero__slider{
    opacity: 0;
    transition: opacity 1s ease;
    position: relative;
    align-items: stretch;


    .slider-item{
      min-height: $hero-2cols-height;
      background-size: cover;
      background-position: center;

      @include breakpoint(medium down) {
        min-height: 387px;      
      } 
      
      img{display: none}

    }
    .slick-list{
      display: flex;
      flex-direction: column;
    }
    .slick-track{
      flex: 1;
    }
    .slick-arrow{
      position: absolute;
      bottom: 16px;
      align-items: center;
      justify-content: center;

      // top: 50%;
      // transform: translateY(-50%);
      background-color: var(--accent-color);
      color: white;
      display: flex;
      width: 50px;
      height: 50px;
      z-index: 1;
      border-radius: 50%;
      opacity: .7;
      transition: all 300ms ease;
      cursor: pointer;

      &:focus{outline: 0;}
      &:hover{
        opacity: 1;
        transform: translateY(-2px);
      }

    }
    .slick-prev{ 
      right: 90px;
      top: 50%;
    }
    .slick-next{ 
      right: 16px;
      top: 50%;
    }
  }

  .hero__content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: $hero-2cols-indent; // 604 = 1208 /2 
    padding-right: 36px;
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: var(--accent-color);
    color: white;

    @include breakpoint(medium down) {
      flex: 0 0 100%;
      max-width: 100%;
      padding: 30px;
    }

    .button{
      margin-top: 16px;
      background: black !important;
      color: white;
      // background: var(--accent-dark-color) !important;

      &:hover{
        // background: white !important;
        // color: var(--accent-color);
      }
    }
  }
  .hero__text{
    max-width: 100%;
    word-wrap: break-word;
  }
  .hero__sub-title,
  .hero__title{
    // text-shadow: 3px 4px 6px rgba(0, 0, 0, 0.5);
  }
  .hero__gallery-button{
    position: absolute;
    bottom: 40px;
    // right: 16px;
    // right: calc(50vw - 604px + 0px);
    right: 30px;
    z-index: 3;

    @include breakpoint(small down) {
      bottom: 30px;
      span{
        display: none;
      }
    }
    
  }

}

// VARIATION => TWO COLS HAS LOGO
.hero--2cols.has-logo{
  .hero__content{
    padding: 0;
  }
  .hero__logo{
    padding-left: $hero-2cols-indent; // 604 = 1208 /2 
    flex: 0 0 206px;
    background-color: #E7E9EC;
    width: 100%;
    display: flex;
    align-items: center;
    img{
      max-width: 206px;
    }
    @include breakpoint(medium down) {
      padding: 30px;
      flex: 1;

    }
  }
  .hero__text{
    padding-left: $hero-2cols-indent; // 604 = 1208 /2 
    background-color: var(--accent-color);
    flex: 1;
    padding-right: 36px;
    padding-top: 40px;
    padding-bottom: 40px;
    
    @include breakpoint(medium down) {
      padding: 30px;
    }
  }


}













