.management {
  // background-image: url("../img/background1.png");
  // background-position: center;
  // background-size: cover;

  @include padding-top(40px, 32px);
  // @include padding-bottom(32px, 32px);

  .management__tenant-services-title {
    margin-top: 16px;
  }

  .management__tenant-services-text {
    @include margin-top(32px, 24px);
  }

  .management__tenant-services-box {
    @include margin-top(32px, 24px);
  }

  .management__tenant-services-box-container {
    justify-content: space-between;
  }

  .management__tenant-services-sub-title {
    // color: $persian-blue;
    font-weight: 600;
  }

  &__content {
    &.left {
      // padding-right: 4%;
    }

    &.right {
      // padding-left: 4%;
    }

    

    div {
        margin-top: 16px;
    }

    &.left>:first-child {
      margin-top: 0;
    }

    // @include breakpoint(small only) {
    //   &.right {
    //     div:first-child {
    //       margin-top: 32px;
    //     }
    //   }
    // }
  }

  &__content-info {
    display: inline-block;
  }

  &__contact-info {
    background-color: $pebble-black;
    color: $white;

    .management__contact-info-title {
      color: $white;
      font-weight: 600;
    }

    .contact-info-table {
      width: 100%;
      height: 100%;
    }

    .contact-info-row {
      background-color: $pebble-black !important;
      border-bottom: 1px solid #FFFFFF20 !important;
      min-height: 54px;
      padding: 8px 0;

      display: flex;
      justify-content: space-between;
      align-items: center;

      @include breakpoint(medium down) {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
      }
    }

    .contact-info-header {
      color: white;
      font-size: 12px;

      @include breakpoint(medium down) {
        .contact-info-row {
          min-height: auto;
        }
        .contact-info-cell {
          display: none;
        }
      }
    }

    .contact-info-cell {
      text-align: left;
      min-width: 33%;
      flex: 1;
      padding-right: .6em;

      @include breakpoint(medium down) {
        width: 100%;
      }
    }

  }

}