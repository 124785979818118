.mm-menubar{
	display: flex;
	padding: 8px 16px;
	align-items: center;
	justify-content: center;
	background: var(--accent-color);

	@include breakpoint(medium down){
		display: none;
	}

	a{
		display: block;
		padding: 8px 16px;
		color: white;
		white-space: nowrap;
		font-size: 18px;
		font-weight: 500;
		text-transform: uppercase;
	}
}