// Foundation Variable Overrides
$header-font-weight: 600;

$grid-container: 1600px;

$button-font-weight: 500 !default;
$button-font-weight: bold;
$button-radius: 5px;
$button-margin: 0;

$grid-margin-gutters: 24px;

@import 'foundation-sites/scss/foundation';
@include foundation-global-styles;
@include foundation-forms;
@include foundation-xy-grid-classes;
@include foundation-button;
@include foundation-reveal;
@include foundation-close-button;
@include foundation-responsive-embed;

@include foundation-float-classes;
@include foundation-visibility-classes;