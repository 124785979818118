$title4-font-size: 68px;
$title4-line-height: 76px;

$title3-font-size: 42px;
$title3-line-height: 52px;

$title2-font-size: 32px;
$title2-line-height: 40px;

$title1-font-size: 28px;
$title1-line-height: 36px;

$sub-head3-font-size: 24px;
$sub-head3-line-height: 32px;

$sub-head2-font-size: 20px;
$sub-head2-line-height: 28px;

$sub-head1-font-size: 18px;
$sub-head1-line-height: 24px;

$body2-font-size: 16px;
$body2-line-height: 25px;

$body1-font-size: 14px;
$body1-line-height: 20px;

$caption2-font-size: 12px;
$caption2-line-height: 16px;

$caption1-font-size: 10px;
$caption1-line-height: 14px;

body {
  color: $almost-black;
}

.px-title-4 {
  font-size: $title4-font-size;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: $title4-line-height;
  letter-spacing: normal;

  // converts to Title-2 on mobile
  @include breakpoint(small only) {
    font-size: $title2-font-size;
    line-height: $title2-line-height;
  }
}

.px-title-3 {
  font-size: $title3-font-size;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: $title3-line-height;
  letter-spacing: normal;
}

.px-title-2 {
  font-size: $title2-font-size;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: $title2-line-height;
  letter-spacing: normal;

  // convert so Sub-Head-3 on mobile
  @include breakpoint(small only) {
    font-size: $sub-head3-font-size;
    line-height: $sub-head3-line-height;
  }
}

.px-title-1 {
  font-size: $title1-font-size;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: $title1-line-height;
  letter-spacing: normal;
}

.px-sub-head-3 {
  font-size: $sub-head3-font-size;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: $sub-head3-line-height;
  letter-spacing: normal;

  // Converts to Sub-Head-2 on mobile
  @include breakpoint(small only) {
    font-size: $sub-head2-font-size;
    line-height: $sub-head2-line-height;
  }
}

.px-sub-head-2 {
  font-size: $sub-head2-font-size;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: $sub-head2-line-height;
  letter-spacing: normal;
}

.px-sub-head-1 {
  font-size: $sub-head1-font-size;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: $sub-head1-line-height;
  letter-spacing: normal;
}

.px-body-2 {
  font-size: $body2-font-size;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: $body2-line-height;
  letter-spacing: normal;
}

.px-body-1 {
  font-size: $body1-font-size;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: $body1-line-height;
  letter-spacing: normal;
}

.px-caption-2 {
  font-size: $caption2-font-size;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: $caption2-line-height;
  letter-spacing: normal;
}

.px-caption-1 {
  font-size: $caption1-font-size;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: $caption1-line-height;
  letter-spacing: normal;
}

@font-face {
  font-family: "sharplaunch";
  src: url("/themes/all/fonts/sharplaunch.eot");
  src: url("/themes/all/fonts/sharplaunch.eot?#iefix") format("embedded-opentype"),
    url("/themes/all/fonts/sharplaunch.woff") format("woff"),
    url("/themes/all/fonts/sharplaunch.ttf") format("truetype"),
    url("/themes/all/fonts/sharplaunch.svg#sharplaunch") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "flexslider-icon";
  src: url("/themes/all/fonts/flexslider-icon.eot");
  src: url("/themes/all/fonts/flexslider-icon.eot?#iefix") format("embedded-opentype"),
    url("/themes/all/fonts/flexslider-icon.woff") format("woff"),
    url("/themes/all/fonts/flexslider-icon.ttf") format("truetype"),
    url("/themes/all/fonts/flexslider-icon.svg#flexslider-icon") format("svg");
  font-weight: normal;
  font-style: normal;
}

[data-icon]:before {
  font-family: "sharplaunch" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "sharplaunch" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-contact:before {
  content: "\61";
}

.icon-gallery:before {
  content: "\62";
}

.icon-leasing:before {
  content: "\63";
}

.icon-location:before {
  content: "\64";
}

.icon-plans:before {
  content: "\65";
}

.icon-play:before {
  content: "\66";
}

.icon-building:before {
  content: "\67";
}

.icon-download:before {
  content: "\68";
}

.icon-zoom:before {
  content: "\69";
}

.icon-linkedin:before {
  content: "\6a";
}

.icon-twitter:before {
  content: "\6b";
}

.icon-facebook:before {
  content: "\6c";
}

.icon-play-1:before {
  content: "\6d";
}

.icon-instagram:before {
  content: "\6f";
}

.icon-download-arrow:before {
  content: "\6e";
}

.icon-circle:before {
  content: "\70";
}

.icon-location-arrow:before {
  content: "\71";
}

.icon-eye:before {
  content: "\72";
}

.icon-eye-slash-close:before {
  content: "\73";
}

.icon-address:before {
  content: "\74";
}

.icon-calendar:before {
  content: "\75";
}

.icon-user:before {
  content: "\76";
}

.icon-down-open-mini:before {
  content: "\77";
}

.icon-square:before {
  content: "\79";
}

.icon-arrow-left:before {
  content: "\78";
}

.icon-arrow-right:before {
  content: "\7a";
}

.icon-handshake:before {
  content: "\41";
}

.icon-tenant:before {
  content: "\42";
}

.icon-getdirections:before {
  content: "\43";
}

.icon-maptype:before {
  content: "\44";
}

.icon-streetview:before {
  content: "\45";
}

.icon-close:before {
  content: "\46";
}

.icon-fullscreen:before {
  content: "\47";
}

.icon-google-plus:before {
  content: "\48";
}

.icon-marker:before {
  content: "\49";
}

.icon-left-open-mini:before {
  content: "\4a";
}

.icon-right-open-mini:before {
  content: "\4b";
}

.icon-up-open-mini:before {
  content: "\4c";
}