.customtext{
	@include padding-top(40px, 32px);

	.section__title{

	}
	.section__subtitle{

	}
	&__content{
		@include margin-top(32px, 24px);
		flex: 0 0 100%;
	}
}