.content{
	p > a,
	a:not(.button){
		text-decoration: underline;
		color: var(--accent-color);
	}

	.responsive-embed{ // .widescreen
		margin-top: 16px;
	}
}