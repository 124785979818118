.building {
  // background-image: url("../img/background1.png");
  background-position: center;
  background-size: cover;

  // @include padding-top(64px, 48px);
  // @include padding-bottom(32px, 32px);

  .building__overview-title {
    margin-top: 16px;
  }

  .building__overview-text {
    @include margin-top(32px, 24px);
  }

  .building__overview-text--align-left {
    max-width: 600px;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
  }

  .building__property-features-title {
    @include margin-top(32px, 24px);
  }
  
  .building__property-info-title {
    margin-top: 16px;
    // @include margin-top(32px, 24px);
  }
  .building__property-info-box {
    + .building__overview-title{
      @include margin-top(32px, 24px);
    }
  }

  $property-features-box-margin: 24px;

  .building__property-features-box {
    @include margin-top(32px, 24px);
    // @include margin-top(32px - $property-features-box-margin, 24px - $property-features-box-margin);
    
    ul{
      list-style-type: none;
      margin: 0;
      padding: 0;
      li{
        display: flex;
        align-items: flex-start;
        margin-bottom: 15px;
        svg{
          flex: 0 0 24px;
          margin-right: 8px;
          color: var(--accent-color);
        }
        em{
          margin-right: 8px;
          margin-top: -1px;
          color: var(--accent-color);
        }
        span{

        }
      }
    }

    .property-features-box {
      // margin-top: $property-features-box-margin;
      // height: calc(100% - 24px);
    }
  }

  .building__property-info-box {
    @include margin-top(32px, 24px);

    .property-info-list{
      padding: 32px 24px;
      ul{
        list-style: none;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 0; margin: 0;
        li{
          // border-top: 1px solid rgba($almost-black, 0.2);
          border-bottom: 1px solid rgba($almost-black, 0.2);
          // flex: 0 0 calc(50% - 15px);
          display: flex;
          padding: 16px 0;
          align-items: flex-start;
          flex: 0 0 100%;

          // @include breakpoint(small down) {
          //   flex: 0 0 100%;
          // }
          
          & + li{
            // border-top: 0;
          }

          &:nth-child(1){
              border-top: 1px solid rgba($almost-black, 0.2);
          }
          &:nth-child(2){
            @include breakpoint(medium up) {
              // border-top: 1px solid rgba($almost-black, 0.2);
            }
          }

          span{
            justify-content: space-between;
            flex: 0 0 49%;

            & + span{
              text-align: right;
            }
          }
        }
      }

    }
  }

  .building__certifications-title {
    @include margin-top(32px, 24px);
  }

  .building__certifications-text {
    @include margin-top(32px, 24px);
  }
  .building__certifications-list {
    margin-top: 24px;
    display: flex;
    align-items: stretch;
    // justify-content: center;
    flex-wrap: wrap;

    a{
      display: flex;
      align-items: center;
      justify-content: center;
      background: white;
      width: 120px;
      height: 120px;
      margin: 0 12px 16px 12px;
      padding: 10px;
      box-shadow: 0 2px 8px 0 rgba(10,11,15,0.1);
      border-radius: 4px;
      
      @include breakpoint(small down) {
        width: 100px;
        height: 100px;
      }

      img{
        max-width: 100%;
        max-height: 100%;
      }
    }
  }

  .building__address-title {
    @include margin-top(32px, 24px);
  }

  .building__address-text {
    @include margin-top(32px, 24px);
  }
}