.tenants {
  @include padding-top(40px, 32px);
  // @include padding-bottom(32px, 32px);
  // background-color: #f0f0f0;

  .tenants__sub-title {
    margin-top: 16px;
  }

  .tenants__text {
    @include margin-top(32px, 24px);
  }
}

$tenant-box-margin: 24px;

.tenant-box-container {
  // @include margin-top(32px, 24px);
  margin-top: 32px - $tenant-box-margin;
  margin-right: -0.75rem;
  margin-left: -0.75rem;
}

.tenant-box {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: unset;
  padding: 16px 24px;
  transition: box-shadow .2s;
  height: 200px;
  border-radius: 5px;
  margin-top: $tenant-box-margin;
  overflow: hidden;

  @include breakpoint(medium down) {
    height: 152px;
    padding: 16px;
  }

  &__hover {
    position: absolute;
    bottom: 0;
    transform: translateY(100%);
    transition: all 600ms $e;
    opacity: 0;
    background: rgba(white, .7);
    text-align: center;
    left: 0;
    right: 0;
    padding: 24px 16px;
  }

  &__logo {
    flex-grow: 1;
    display: flex;
    align-items: center;

    img {
      margin: auto;
      @include max-dimensions(100px, 100%);
    }
  }

  &__address {
    font-weight: 600;
    font-size: 16px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.38;

  }
  &__link {
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.38;

  }
  &.has-hover:hover {
    box-shadow: 0 16px 32px 0 rgba($almost-black,0.2);
    // box-shadow: 0 8px 24px 0 rgba($almost-black, 0.2);
    cursor: pointer;

    .tenant-box__hover{
      transform: translateY(0%);
      opacity: 1;
    }
  }
}