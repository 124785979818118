.leasing {
  // background-color: #f0f0f0;
  // background-position: center;
  // background-size: cover;

  @include padding-top(40px, 32px);
  // @include padding-bottom(32px, 32px);

  .leasing__brokerage-team-title {
    margin-top: 16px;
  }

  .leasing__brokerage-team-text {
    @include margin-top(32px, 24px);
  }

  $team-member-margin: 24px;

  .leasing__brokerage-team-members {
    justify-content: center;
    margin-top: 32px - $team-member-margin;
  }

  .team-member__container {
    height: 100%;
    display: flex;
  }

  .team-member {
    text-align: left;
    position: relative;
    padding: 32px 24px;
    margin: $team-member-margin 0 0;
    transition: box-shadow .2s;
    box-shadow: 0 16px 32px 0 rgba(10, 11, 15, 0.1);
    display: flex;

    @include breakpoint(small down) {
      flex-wrap: wrap;
      justify-content: center;
      text-align: center;
    }


    &:hover {
      // box-shadow: 0 16px 32px 0 rgba(10,11,15,0.2);
    }

    &__avatar {
      height: 100px;
      width: 100px;
      flex: 0 0 100px;
      margin: auto;
      border-radius: 50%;
      overflow: hidden;
      @include max-dimensions(100px, 100px);
      margin-right: 24px;

      @include breakpoint(small down) {
        margin-right: auto;
        margin-left: auto;
        // flex: 0 0 100%;
      }

      img {
        width: 100px;
        height: 100px;
        object-fit: cover;
      }
    }

    &__content {
      flex: 1;

      &,
      &>* {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }


      @include breakpoint(small down) {
        flex: 0 0 100%;
      }
    }

    &__line {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 10px;
      background-color: var(--accent-color);
    }

    &__job {
      white-space: normal;
    }

    &__name {
      font-weight: 500;

      overflow: visible;
      white-space: normal;

      @include breakpoint(small down) {
        margin-top: 24px;
      }
    }

    &__email {
      text-overflow: ellipsis;
      overflow-x: hidden;
    }

    .team-member__identities {
      div:first-child {
        margin-top: 4px;
      }

      b {
        display: block;
      }
    }
  }

  .leasing-member__container {
    width: 336px;
    height: 164px;
    box-shadow: 0 16px 32px 0 rgba($almost-black, 0.1);
    background-color: $white;
  }

  &.leasing--slim {
    .team-member {
      text-align: left;
      // padding-left: 42px;

      &__name {
        margin-top: 0;
      }
    }
  }
}