body, html{
  max-width: 100%;
  overflow-x: hidden;  
}
body {
  &.menu-active {
    // Disable Scrolling in the app
    overflow: hidden;
  }
}

.grid-container{
  max-width: 1208px;
}
// .page-content {
//   padding: 0 4%;

//   @media (max-width: 380px) {
//     padding: 8px;
//   }
// }
.flex{
  display: flex;
}
.box {
  box-shadow: 0 2px 8px 0 rgba(10, 11, 15, 0.1);
  width: 100%;
  padding: 24px;
  background-color: white;

  @include breakpoint(medium down) {
    padding: 16px;
  }
}
h1,h2,h3,h4,h5{
  margin-top: 0;
  margin-bottom: 0;
}
a {
  text-decoration: none;
  color: inherit;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.align-center {
  -webkit-box-pack: center;
  justify-content: center;
}

.docportal{
  .section-title__text{
    margin-bottom: 32px;
  }
}