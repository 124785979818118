.virtualtour {
  @include padding-top(40px, 32px);

  &__wrap {
    @include margin-top(32px, 16px);
    background-size: cover;
    background-position: center;

    height: 38vw;
    // color: $white;
    padding-bottom: 32px;
    padding-left: 0;
    padding-right: 0;
    position: relative;

    @include breakpoint(medium down){
      height: 55vw;
    }
  }
  &__embed{
    @include margin-top(16px, 8px);
  }
  &__inlineplayer{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: none;
    z-index: 1;
    background: rgba(black, .2);
    width: 100%;
    height: 100%;
    &.is-activated{
      display: block;
    }

  }
  .play-button {
    background: rgba(0, 0, 0, .75);
    position: absolute;
    left: 50%;
    top: 50%;
    color: #FFF;
    width: 120px;
    height: 70px;
    line-height: 77px;
    margin-left: -60px;
    text-align: center;
    font-size: 40px;
    border-radius: 5px;
    transform: translateY(-50%);
    transition: all 300ms $e;

    &:hover{
      background: rgba(0, 0, 0, .95);
    }
  }

  &__overlay-cta{
    margin-top: 32px;
  }
}
