.location {
  // background-position: center;
  // background-size: cover;
  // margin-top: -40px;
  background-color: #f0f0f0;
  // outline: 1px solid red;
  // height: 110vh;
  @include margin-top(40px, 32px);
  // padding-bottom: 32px;
  padding-left: 0;
  padding-right: 0;
  position: relative;
  
  @include breakpoint(medium down) {
    margin-top: 0;
  }

  .location__title-bar {
    display: none;
    // position: absolute;
    // width: 100%;
    // height: 120px;
    // top: 0;
    // background-color: rgba($white, 0.8);
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // z-index: 1;
    margin-bottom: 32px;
    .section-title{
      padding-left: 12px;
    }


  }
}

#exit-full-screen {
  display: none;
  position: fixed;
  z-index: 1010;
  right: 45px;
  top: 10px;
  font-size: 40px;
  height: 10px;
  width: 10px;
}

/* @group LOCATION */
#map_canvas {
  width: 100%;
  float: left;
  background: #333;
  height: 100%;

  &.fullscreen {
    position: fixed !important;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
    z-index: 900;

  }
}

#map-container {
  width: 100%;
  position: relative;
  overflow: hidden;
  // height: 950px;
  height: 90vh;
}

// @media screen and (min-height: 800px) {
//   #map-container {
//     height: 110vh;
//   }
// }

#map-tooltip {
  background: #FFF;
  position: absolute;
  text-align: center;

  height: initial;
  display: block;
  width: auto;
  z-index: 1;
  width: 327px;
  height: 70px;
  padding: 10px;
  min-height: 127px;
  max-height: 130px;
  border-radius: 5px;
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.3);

  &.regular {
    width: 240px !important;
    padding: 10px !important;
    margin-left: -100px !important;

    .tooltip-content {
      top: 50%;
      transform: translateY(-50%);
      position: absolute;
      text-align: center;
      width: 215px;
      word-break: break-word;

      h3 {}
    }
  }

  &.logo {
    width: 70px;
    height: 70px;


    padding: 10px;

    h3 {
      text-align: left;
      margin-top: 5px
    }

    img {
      float: left;
      vertical-align: middle;
      position: relative;
      top: 50%;
      max-height: 100%;
      transform: translateY(-50%);
    }

    span {
      text-align: left;
      display: block
    }

    .tooltip-content {
      top: 50%;
      transform: translateY(-50%);
      position: absolute;
      left: 30px;
      width: 193px;
    }
    img + .tooltip-content {
      left: 122px;
    }

    &:after {
      display: none !important;
    }
  }

  &:after {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #ffffff;
    border-width: 10px;
    margin-left: -10px;
  }

  h3 {

    font-size: 18px;
    margin: 0;
    line-height: 1.2em;
    font-weight: 700;
  }

  span {
    font-size: 14px;
  }
}


.popup-tip-anchor {
  height: 0;
  position: absolute;
  /* The max width of the info window. */
  width: 200px;
}

/* The bubble is anchored above the tip. */
.popup-bubble-anchor {
  position: absolute;
  width: 100%;
  bottom:
    /* TIP_HEIGHT= */
    8px;
  left: 0;

}

/* Draw the tip. */
.popup-bubble-anchor::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  /* Center the tip horizontally. */
  transform: translate(-50%, 0);
  /* The tip is a https://css-tricks.com/snippets/css/css-triangle/ */
  width: 0;
  height: 0;
  /* The tip is 8px high, and 12px wide. */
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top:
    /* TIP_HEIGHT= */
    0px solid white;

}

/* The popup bubble itself. */
.popup-bubble-content {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, -100%);
  /* Style the info window. */
  background-color: white;
  padding: 5px;
  border-radius: 5px;
  font-family: sans-serif;
  overflow: hidden;
  width: 60px;
  height: 60px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  cursor: hand;

  img {
    position: relative;
    top: 50%;
    width: 100%;
    max-height: 100%;
    transform: translateY(-50%);
  }

  &:hover {

    width: 63px;
    height: 63px;

    img {
      top: 50%;
      width: 100%;
      transform: translateY(-50%);
    }

    i {
      font-size: 50px;
    }

    z-index:9999999999;
  }

  &.shadow {
    box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.3);
  }
  &.is-svg-custom{
    box-shadow: none;
    background-color: transparent;
    border-bottom: 0 !important;
  }
  &.is-property{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.5;
    border-radius: 8px;
    padding: .75em;
    width: 105px;
    height: 105px;
    background-color: white;
    border: 5px solid var(--accent-color) !important;
    color: $almost-black;
    overflow: inherit;
    transform: translate(-8px, -82px); // adjust location to match with ::after arrow
    &::after{
        content: '';
        display: block;
        width: 0;
        height: 0;
        bottom: 0;
        position: absolute;
        left: 50%;
        bottom: -22px;
        margin-left: -10px;
        border: 10px solid transparent;
        border-top: 12px solid var(--accent-color);
    }
    &::before{
        content: '';
        display: block;
        width: 0;
        height: 0;
        bottom: 0;
        position: absolute;
        left: 50%;
        bottom: -14px;
        margin-left: -8px;
        border: 8px solid transparent;
        border-top-color: white;
        z-index: 1;
    }
  }
}



#map-categories {
  position: absolute;
  left: 0;
  bottom: -100px;
  right: 0;
  /*height: 50px;*/
  background: rgba(255, 255, 255, .8);

  &.active {
    bottom: 0;
    transition-duration: 0.2s;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: center;
    width: 100%;
    float: left;
    vertical-align: top;

    li {
      cursor: pointer;
      display: inline-block;
      vertical-align: top;
      line-height: 50px;
      padding: 0 10px;
      opacity: 0.5;

      i {
        float: left;
        margin-right: 5px;
        position: relative;
        bottom: -3px;
      }

      &.active {
        opacity: 1;
      }
    }
  }
}

.zoom-control {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  // width: 70%;
  height: 50px;
  background: $persian-blue;
  top: 0px;
  bottom: auto;

  .zoom-container {
    display: flex;
    align-items: stretch;
    font-size: 13px;
    color: white;
    padding-left: 16px;
    padding-right: 16px;
    border-right: 1px solid rgba(0,0,0,.2);

    .zoom-label {
      display: flex;
      align-items: center;
      padding-right: 16px;
    }
    
    .zoom-in {
      display: flex;
      align-items: center;
      // padding-left: 7px;
    }

    .zoom-out {
      display: flex;
      align-items: center;
      // border-right: 1px solid rgba(0,0,0,.2);
      padding-right: 7px;
    }
    .zoom-in,
    .zoom-out {
      cursor: pointer;
      &:hover{
        color: var(--accent-dark-color);
      }
    }
  }
  

  .button {
    // position: absolute;
    // top: 0;
    // padding: 0;
    padding-left: 16px;
    outline: none;
    user-select: none;
    padding-right: 16px;
    // width: 50px;
    line-height: 45px;
    margin: 0;

    border: 0;
    height: 50px;
    color: #FFF;
    border-radius: 0;
    white-space: nowrap;

    display: flex;
    align-items: center;
    justify-content: center;

    i {
      font-size: 24px;
      top: 7px;
      position: relative;
      margin-right: 2px;
    }
    span{

    }
    svg+span{
      margin-left: .6em;
    }


    

    &.map-type {
      font-size: 13px !important;
      white-space: nowrap;

      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid rgba(0,0,0,.2);


      .map-type-options:after {
        z-index: 0;
        position: absolute;
        // bottom: -10px;
        left: 67%;
        margin-left: -25%;
        content: '';
        width: 0;
        height: 0;
        border-top: solid 10px #fff;
        border-left: solid 10px transparent;
        border-right: solid 10px transparent;
        top: -10px !important;
        border-bottom: 10px solid #fff !important;
        border-top: none !important;
      }

      .map-type-options {
        display: none;
        border-radius: 4px;
        background: white;
        width: 166px;
        position: relative;
        left: -20px;
        line-height: 42px;
        top: 16px;

        position: absolute;
        top: 130%;
        left: 0;
        box-shadow: 0 2px 15px 0 rgba(0,0,0,0.25);

        ul {
          margin: 0px;
          padding: 0;
          list-style: none;
          text-align: left;

          li {
            text-align: left;
            height: 71px;
            padding:15px;

            img {
              float: left;
              width: 64px;
              height: 45px;
              border: 1px solid #e7e7e7;
              margin-right: 16px;
            }

            a {
              text-align: left;
              color: $almost-black;
              font-size: 16px;
              display: block;
            }

            &:hover {
              background: var(--accent-color);
              color: white;

              a {
                color: white;
              }

              &:first-child {
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
              }

              &:last-child {
                border-bottom-left-radius: 4px;
                border-bottom-right-radius: 4px;
              }
            }

            &:last-child {
              height: 74px;
            }
          }
        }
      }

      &.open {
        .map-type-options {
          display: block;
        }
      }
    }

    &.street-view {
      // right: 120px;
      font-size: 14px;
      // width: 120px;
      font-size: 13px !important;
      white-space: nowrap;
      line-height: 42px;
      border-right: 1px solid rgba(0,0,0,.2);
    }

    &.get-directions {
      // right: 240px;
      font-size: 13px !important;
      // width: 136px;
      // padding-right: 3px;
      line-height: 44px;
      border-right: 1px solid rgba(0,0,0,.2);
    }

    &.fullscreen {
      // width: 120px;
      // right: 0px;
      font-size: 13px !important;
      white-space: nowrap;
      line-height: 42px;
      border-right: 1px solid rgba(0,0,0,.2);
    }

    a {
      color: #FFF;
      height: 50px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      outline: none;
      user-select: none;

      svg{
        margin-right: .6em;
      }
    }
  }

  // #zoom-slider {
  //   left: 50px;
  //   right: 55px;
  //   position: absolute;
  // }
}

body.map-full-screen {
  overflow: hidden;

  #exit-full-screen {
    display: block;
  }

  #map-categories {
    z-index: 1001;
    position: fixed;
  }

  .zoom-control {
    position: fixed;
    top: 0px !important;
    z-index: 1000;
  }

  #map-tooltip {
    z-index: 999 !important;
    position: fixed;
  }
}

.call-banner {
  position: absolute;
  top: 20px;
  right: 0px;
  color: #FFF;
  z-index: 10;
  line-height: 1.6;
  background: url(/img/courtyard-banner-bg.png) no-repeat left center;
  font-weight: 700;
  padding: 10px 20px 10px 80px;
  z-index: 11;
  top: 99px;
}


#slider {
  padding-top: 60px;
  padding-bottom: 60px;
  background: white;
  text-align: center;

  .section-title h2 {
    padding: 0px;
    margin: 0px;
    margin-bottom: 50px;
  }

  .flexslider {
    margin: 0px !important;
    margin-top: 10px !important;
  }

  h3 {
    padding: 0px;
    margin: 0px;
    margin-bottom: 20px;
  }

  .flex-control-nav.flex-control-paging {
    display: none;
  }

  .flex-control-paging li a {
    width: 24px;
    font-size: 15px;
    color: white !important;
    height: 24px;
    text-indent: 0px;
  }

  #flexslider-counter h3 {
    font-size: 22px;
    margin-bottom: 0px;
  }

  .slides {
    background: #e6eaed;

    li {
      height: 500px;
      overflow: hidden;
    }

    .slide {
      height: 500px;

      .slide-details {
        font-size: 14px;
        text-align: left;
        padding-top: 66px;
        padding-bottom: 66px;
        padding-left: 50px;
        padding-right: 50px;

        h4 {
          font-size: 36px;
          margin: 0px;
          margin-bottom: 40px;
          padding: 0px;
          color: #666666;
          line-height: 36px;
        }

        h6 {
          font-size: 14px;
          text-transform: uppercase;
          font-weight: bold;
          font-family: 'Lato', sans-serif !important;
          margin: 0px;
        }

        p {
          font-size: 18px;
          color: #666666;
        }
      }

      .slide-image {
        background: #e6eaed;
        padding: 0px;

        img {
          width: auto;
          height: 100%;
          float: right;
        }
      }
    }
  }
}

/*! jQuery UI - v1.11.2 - 2015-01-29
* http://jqueryui.com
* Includes: core.css, slider.css
* Copyright 2015 jQuery Foundation and other contributors; Licensed MIT */
/* Layout helpers
----------------------------------*/
.ui-helper-hidden {
  display: none;
}

.ui-helper-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.ui-helper-reset {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  line-height: 1.3;
  text-decoration: none;
  font-size: 100%;
  list-style: none;
}

.ui-helper-clearfix:before,
.ui-helper-clearfix:after {
  content: "";
  display: table;
  border-collapse: collapse;
}

.ui-helper-clearfix:after {
  clear: both;
}

.ui-helper-clearfix {
  min-height: 0;
  /* support: IE7 */
}

.ui-helper-zfix {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  opacity: 0;
  filter: Alpha(Opacity=0);
  /* support: IE8 */
}

.ui-front {
  z-index: 100;
}

/* Interaction Cues
----------------------------------*/
.ui-state-disabled {
  cursor: default !important;
}

/* Icons
----------------------------------*/
/* states and images */
.ui-icon {
  display: block;
  text-indent: -99999px;
  overflow: hidden;
  background-repeat: no-repeat;
}

/* Misc visuals
----------------------------------*/
/* Overlays */
.ui-widget-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ui-slider {
  position: relative;
  text-align: left;
}

.ui-slider .ui-slider-handle {
  position: absolute;
  z-index: 0;
  width: 5px;
  height: 10px;
  cursor: pointer;
  -ms-touch-action: none;
  touch-action: none;
  background: #FFF;

  &:focus {
    outline: none;
  }
}

.ui-slider .ui-slider-range {
  position: absolute;
  z-index: 1;
  font-size: .7em;
  display: block;
  border: 0;
  background-position: 0 0;
}

/* support: IE8 - See #6727 */
.ui-slider.ui-state-disabled .ui-slider-handle,
.ui-slider.ui-state-disabled .ui-slider-range {
  filter: inherit;
}

.ui-slider-horizontal {
  height: 24px;
  margin-top: 13px;

  &:after {
    content: "";
    border-top: 1px solid rgba(#FFF, 1);
    width: 100%;
    position: absolute;
    left: 0;
    top: 50%;
  }
}

.ui-slider-horizontal .ui-slider-handle {
    top: 50%;
    transform: translateY(-46%);
}

.ui-slider-horizontal .ui-slider-range {
  top: 0;
  height: 100%;
  /*background-color: rgba(0,0,0,.25);*/
}

.ui-slider-horizontal .ui-slider-range-min {
  left: 0;
}

.ui-slider-horizontal .ui-slider-range-max {
  right: 0;
}

.ui-slider-vertical {
  width: .8em;
  height: 100px;
}

.ui-slider-vertical .ui-slider-handle {
  left: -.3em;
  margin-left: 0;
  margin-bottom: -.6em;
}

.ui-slider-vertical .ui-slider-range {
  left: 0;
  width: 100%;
}

.ui-slider-vertical .ui-slider-range-min {
  bottom: 0;
}

.ui-slider-vertical .ui-slider-range-max {
  top: 0;
}