.section-title {
  // text-align: center;
  // text-transform: uppercase;
  display: flex;
  // justify-content: center;
  flex-direction: row;
  // align-items: center;

  $separator-length: 30px;

  &__text {}

  // &__separator {
  //   width: 0;
  //   margin: 0 16px;
  //   height: 2px;
  //   transition: width 1300ms ease-out; 

  //   .aos-animate &{
  //     width: $separator-length;
  //   }
  // }

  &.section-title--white {
    .section-title__text {
      color: $white;
    }

    .section-title__separator {
      background-color: $white;
    }
  }

}

.section-sub-title {
  font-weight: 600;
  // color: $persian-blue;
  // text-align: center;
  margin-top: 16px;
}
.section-description {
  @include margin-top(32px, 24px);

  ul, ol{
    max-width: 580px;
    // margin-left: auto;
    // margin-right: auto;
    text-align: left;
  }
}


////@include
.right-section {
  position: relative;

  &::before {
    position: relative;
    content: "";
    background-color: red;
    width: 10px;
    height: 10px;
  }
}