.contact {
	@include padding-top(40px, 24px);
	.box{
		margin-top: 32px;
	}
	&-text {
		margin-top: 16px;
	}
	&-form {

		label {
			// color: white;
			text-transform: uppercase;
			font-weight: 600;
			font-size: 12px;
			margin-top: 24px;
		}

		input,
		textarea {
			// background-color: black !important;
			// border: none !important;
			// color: white !important;
			// outline: none !important;
			// box-shadow: none !important;
			border-radius: 3px !important;
			font-size: 14px;

			&::placeholder,
			&:-ms-input-placeholder,
			&::-ms-input-placeholder {
				color: $pearl-white;
			}
		}

		input {
			height: 32px !important;
			margin: 0;
			margin-top: 4px;
		}

		textarea {
			height: 121px !important;
			margin: 0;
			margin-top: 4px;
		}

		.contact__name {
			margin-top: 0;
		}
		.contact__website {
			position: absolute;
			left: -9999px;
		}

		.contact__button {
			margin-top: 24px;
		}
	}

	.leasing__brokerage-team-title {
		margin-top: 16px;
	}

	.leasing__brokerage-team-text {
		@include margin-top(32px, 24px);
	}
}

.contact{
	&-footer{
		background: #191919;
		padding: 40px 0 40px;
		color: white;
		&__title{
			margin-bottom: 16px;
		}
	}
}
