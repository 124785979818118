.availabilities {
  position: relative;
  // background-image: url("../img/availabilities.png");
  background-position: center;
  @include padding-top(40px, 32px);
  @include padding-bottom(32px, 32px);
}

.availability-carousel {
  display: flex;
  justify-content: center;
  margin-top: 32px;
  position: relative;

  @include breakpoint(small only) {
    margin-top: 36px;
  }

  .availability-carousel__arrow-left {
    left: calc(13% - 60px);

    &::after {
      content: '\f001';
    }
  }

  .availability-carousel__arrow-right {
    right: calc(13% - 60px);

    &::after {
      content: '\f002';
    }
  }

  .availability-carousel__arrow-left,
  .availability-carousel__arrow-right {
    position: absolute;
    top: 45%;
    cursor: pointer;
    z-index: 1;

    &::after {
      font-family: flexslider-icon;
      font-size: 32px;
      display: inline-block;
      color: rgba(0, 0, 0, .8);
      text-shadow: 1px 1px 0 rgba(255, 255, 255, .3);
    }

    @include breakpoint(small only) {
      display: none;
    }
  }

  .availability-carousel__container {
    overflow: hidden;
    width: calc(75% + 64px);
    // padding-top: 16px;
    padding-bottom: 48px;
    margin-bottom: -48px;

    @include breakpoint(small only) {
      width: 100%;
    }
  }
}

.availability-cards {
  display: flex;
}

.availability-card-group-container {
  display: flex;
  position: relative;
  transition: left 0.3s ease;
  left: 0;

  @include breakpoint(small only) {
    flex-direction: column;
  }
}

.availability-card-group {
  display: flex;
  padding: 0 24px;
  width: 100%;
  justify-content: center;

  flex-shrink: 0;
  flex-grow: 0;

  @include breakpoint(small only) {
    flex-direction: column;

    &:first-child {
      margin-bottom: 16px;
    }

    &:last-child {
      margin-top: 16px;
    }
  }
}

.availability-card-container {
  width: 33.33%;
  flex-shrink: 0;
  flex-grow: 0;

  padding: 0 12px;

  @include breakpoint(small only) {
    width: auto;
    margin: 16px 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

@keyframes bounce-card-download-icon {
  0%,
  25%,
  50%,
  75%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-6px);
  }
  60% {
    transform: translateY(-3px);
  }
}

.availability-card {
  position: relative;
  height: 338px;
  border-radius: 5px;
  transition: all 1.2s $e;
  will-change: transform, box-shadow;
  // box-shadow: 0 16px 32px 0 rgba($almost-black, 0.1);
  // background-color: $persian-blue;
  box-shadow: 0 2px 8px 0 rgba($almost-black, 0.1);
  padding: 24px;

  @include breakpoint(small only) {
    height: auto;
    padding-bottom: 60px;
  }


  &__title {
    color: $white;
    font-size: 20px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.4;
    letter-spacing: normal;
  }

  &__divider {
    background-color: $white;
    opacity: 0.2;
    margin: 0;
  }

  &__properties {
    margin-top: 39px;
  }
  
  &__thumbnail {
    margin-top: auto;
    padding-top: 20px;
    padding-bottom: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    img{
      border-radius: 6px;
      max-height: 140px;
      max-width: 100%;
    }
  }

  &__property {
    text-align: left;
    color: $white;
    padding: 4px 0;
    min-height: 28px;
  }

  &__download-button {
    position: absolute;
    width: 100%;
    text-align: center;
    height: 41px;
    background-color: $persian-blue-dark;
    border-radius: 0 0 5px 5px;
    left: 0;
    bottom: 0;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    .availability-card__download-button-image {
      padding-bottom: 4px;
      color: $white;
    }

    .availability-card__download-button-text {
      color: $white;
      font-weight: 500;
      margin-left: 8px;
      vertical-align: text-top;
    }
  }
  &:hover {
    transform: scale(1.02) translateY(3px);
    box-shadow: 0 8px 16px 0 rgba($almost-black, 0.2);
    transition: all .2s $e;

    .availability-card__download-button-image {
      .svg-download-arrow{
        animation: bounce-card-download-icon 2s infinite;
      }
    }
  }

  &.has-thumbnail{
    display: flex;
    flex-direction: column;
  }

}

.availability-card__virtual-tour {
  font-weight: 500;
  text-align: center;
  margin-top: 8px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  .availability-card__virtual-tour-image {
    padding-bottom: 4px;
  }

  .availability-card__virtual-tour-text {
    font-weight: 500;
    margin-left: 8px;
    vertical-align: text-top;
  }

  &:hover{
    color: var(--accent-color)
  }
}

// Rows Variation ======================================= //
.availability__content {
  margin-top: 32px;

  .availability__row-line {
    height: 1px;
    width: 100%;
    background-color: rgba($almost-black, 0.2);
  }

  .availability__row {
    display: flex;
    justify-content: space-between;
    min-height: 88px;
    padding: 18px 0; // 2 lines of title is taller than 88
    align-items: center;

    @include breakpoint(medium down) {
      flex-direction: column;
      align-items: flex-start;
      padding: 24px 0;
    }

    .availability__row-content {
      &.has-thumbnail{
        @include breakpoint(medium up) {
          display: flex;
          align-items: center;
          img{
            margin-right: 1em;
            max-width: 70px;
            max-height: 40px;
            border-radius: 3px;
          }
        }
      }
    }
    .availability-row__thumbnail{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 70px;
      height: 40px;
      @include breakpoint(medium down) {
        display: none;
      } 
    }
    .availability__row-title {
      font-weight: 500;
    }

    .availability__row-info {
      margin-top: 8px;
    }

    .availability__row-buttons {
      display: flex;
      justify-content: space-between;
      font-weight: 500;
      margin-left: 16px; 

      @include breakpoint(medium down) {
        margin-left: 0;
        margin-top: 24px;
      }

      a{
        white-space: nowrap;
      }

      .availability__row-floor-plan {
        margin: 0 8px;
        cursor: pointer;
        transition: color .2s;
        display: flex;
        align-items: center;

        // &:hover {
          // color: rgba($almost-black, 0.8)
        // }

        .availability__row-download-button-image {
          // padding-bottom: 4px;
        }

        .availability__row-download-button-text {
          margin-left: 8px;
        }
      }

      .availability__row-virtual-tour {
        margin-left: 8px;
        margin-right: 24px;
        cursor: pointer;
        transition: color .2s;
        display: flex;
        align-items: center;

        // &:hover {
        //   color: rgba($almost-black, 0.8);
        // }

        .availability__row-virtual-tour-image {
          // padding-bottom: 4px;
        }

        .availability__row-virtual-tour-text {
          margin-left: 8px;
        }
      }

    }
  }

}