.video__section {
  @include padding-top(40px, 32px);
}
.video {
  // background-image: url('https://placeimg.com/1300/700/arch');
  background-size: cover;
  background-position: center;

  height: 500px;
  padding-bottom: 32px;
  padding-left: 0;
  padding-right: 0;
  position: relative;
  margin-top: 24px;


  .video__title-bar {

    @include breakpoint(medium down) {
    }
  }

  .play-button {
    background: rgba(0, 0, 0, .75);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateY(-50%);
    color: #FFF;
    width: 120px;
    height: 70px;
    line-height: 77px;
    /*margin-top: -35px;*/
    margin-left: -60px;
    text-align: center;
    font-size: 40px;
    border-radius: 5px;
  }
}

.pswp__zoom-wrap {
  text-align: center;

  &:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
}

.wrapper {
  line-height: 0;
  width: 100%;
  max-width: 900px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045;
}

.video-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 25px;
  height: 0;
  width: 100%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

video {
  width: 100% !important;
  height: auto !important;
}