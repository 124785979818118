.mobile-menu {
    position: fixed;
    height: calc(100vh + 88px);
    background-color: $white;
    // background-color: $almost-black;
    top: 0;
    overflow: hidden;

    padding-top: 64px;
    padding-bottom: 64px;
    padding-left: 0;
    padding-right: 0;


    // Base State
    $mobile-menu-transition-duration: .6s;

    left: 10%;
    width: 0px;
    opacity: 0;
    transition: opacity $mobile-menu-transition-duration ease-in,
        left $mobile-menu-transition-duration ease-in,
        padding-left 0s $mobile-menu-transition-duration,
        padding-right 0s $mobile-menu-transition-duration,
        width 0s $mobile-menu-transition-duration;

    @include breakpoint(medium up) {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .mobile-menu__content {

        @media (max-width: 950px) and (min-width: 600px) {
            grid-template: auto / auto auto;
        }

        @include breakpoint(medium up) {
            flex: 0 0 auto;
            padding: 88px calc(3vw);
            display: grid;
            grid-template: auto / auto auto auto;
            grid-column-gap: 80px;
            grid-row-gap: 24px;
            
        }
        .header__nav-link{
            margin-top: 16px;
            padding: 0;
            transform: translateX(100px);
            transition: all 800ms $e;
            font-size: 24px;
            line-height: 32px;

            @include breakpoint(medium up) {
                font-size: 28px;
                line-height: 36px;
                margin-top: 0;
                padding: 0px 0px;
                opacity: 0;
                display: inline-block;
                position: relative;
            }

            a{
                span{
                    position: relative;

                }
                &.is-active{
                    span{
                        color: #6B6B6E;
                        &:before{
                            display: block;
                            content: '';
                            position: absolute;
                            left: 0;
                            right: 0;
                            bottom: -8px;
                            height: 3px;
                            background: var(--accent-color);
                        }
                    }
                }
            }
        }
        .header__nav-button {
            @include breakpoint(medium up) {
                position: absolute;
                top: -20px;
                left: 72px;
            }
        }
        
        :nth-child(1){transition-delay: 0ms}
        :nth-child(2){transition-delay: 50ms}
        :nth-child(3){transition-delay: 100ms}
        :nth-child(4){transition-delay: 140ms}
        :nth-child(5){transition-delay: 200ms}
        :nth-child(6){transition-delay: 220ms}
        :nth-child(7){transition-delay: 260ms}
        :nth-child(8){transition-delay: 300ms}
        :nth-child(9){transition-delay: 340ms}
        :nth-child(10){transition-delay: 360ms}
        :nth-child(11){transition-delay: 380ms}
        :nth-child(12){transition-delay: 390ms}
        :nth-child(13){transition-delay: 400ms}
        :nth-child(14){transition-delay: 440ms}
    }

    .header__nav-button {
        margin-top: 48px;
        padding: 0;
        opacity: 0;
        transform: scale(.7);
        transition: all 800ms $e;
        transition-delay: 400ms;
    }
    &.active {
        transition: opacity $mobile-menu-transition-duration ease-in,
            left $mobile-menu-transition-duration ease-in,
            padding-left 0s,
            padding-right 0s,
            width 0s;

        opacity: 1;
        left: 0;
        width: 100vw;
        padding-left: 32px;
        padding-right: 32px;
        overflow: scroll;

        .header__nav-button {
            opacity: 1;
            transform: scale(1);
        }
        .header__nav-link {
            transform: translateX(0px);
            opacity: 1;
        }
    }
}

.header.header--black,
.header.header--black-opaque {
    .mobile-menu {
        background-color: $almost-black;

        .mobile-menu__line {
            background-color: $white;
        }

        .header__nav-link {
            color: $white;
        }

    }
}