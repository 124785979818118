.footer {
  background-color: $pebble-black;
  justify-content: center;
  padding-top: 64px;

  @include padding-bottom(48px, 72px);

  .footer-container {
    padding: 0 4%;
  }

  .footer__logo {
    max-width: 200px;
    max-height: 150px;
    align-self: center; // safari bug
  }

  .section-title {
    color: white;
  }

  .section-sub-title {
    color: white;
    margin-top: 32px;
  }

  .contact-form {
    margin-top: 8px;
  }

  label {
    color: white;
    text-transform: uppercase;
    font-weight: 600;

    margin-top: 24px;
  }

  input,
  textarea {
    background-color: black !important;
    border: none !important;
    color: white !important;
    outline: none !important;
    box-shadow: none !important;
    border-radius: 3px !important;

    //https://stackoverflow.com/questions/7073396/disable-zoom-on-input-focus-in-android-webpage
    touch-action: none;

    &::placeholder,
    &:-ms-input-placeholder,
    &::-ms-input-placeholder {
      color: $pearl-white;
    }
  }

  input {
    height: 32px !important;
    margin: 0;
    margin-top: 8px;
  }

  textarea {
    height: 121px !important;
    margin: 0;
    margin-top: 8px;
  }

  .footer__website {
    position: absolute;
    left: -9999px;
  }

  &__button {
    margin-top: 24px;
  }
  &__logo__container {
    margin-bottom: 32px;
    align-items: center;
    @include breakpoint(medium down) {
     justify-content: center;
    }
  }
  &__logo {

  }
  &__address {
    margin-top: 48px;
    color: white;
    text-align: center;
    font-style: normal;
  }

  &__social-media-icons {
    display: flex;
    margin-top: 16px;
    @include breakpoint(medium down) {
     justify-content: center;
    }

    a{
      transition: all 1300ms ease-out;
      &:hover{
        transform: scale(1.1);
        transition: all 300ms ease-out;
      }
    }
    img {
      margin-right: 16px;
    }


  }
  &__column-right{
    text-align: left;
    @include breakpoint(medium down) {
      text-align: center;
    }
  }


  /*
   * MODIFIERS
   */

  &.footer--white {
    background-color: $white;

    .section-title {
      color: $almost-black;
    }

    .section-sub-title {
      color: $almost-black;
    }

    .contact-form {}

    label {
      color: $almost-black;
    }

    input,
    textarea {
      background-color: $white !important;
      color: $almost-black !important;
      border: 1px solid $pearl-white !important;

      &::placeholder,
      &:-ms-input-placeholder,
      &::-ms-input-placeholder {
        color: $pearl-white;
      }
    }

    .footer__address {
      color: $almost-black;
    }

  }

  &.footer--two-column {
    padding-top: 0;

    .section-sub-title{
      text-align: center;
    }
    .section-title,
    .footer__address {
      text-align: left;
      // @include breakpoint(large down) {
      @media screen and (max-width: 63.9em){
        text-align: center;
      }
    }
    .footer__address {
    }
    .footer__address__item {
      @include breakpoint(large up) {
        display: flex;
        flex-wrap: wrap;
      }

    }
    .footer__address__label {
      display: inline-block;
      @include breakpoint(large up) {
        display: block;
        flex: 0 0 130px;
        // outline: 1px solid red;
        padding-right: 10px;
        margin-bottom: 5px;
      }
      &.is-address{
        @media screen and (max-width: 63.9em){
          display: none;
        }
      }
    }
    .footer__address__content {
      display: inline-block;
      @include breakpoint(large up) {
        display: block;
        flex: 0 0 calc(89% - 135px);
        // outline: 1px solid blue;
        margin-bottom: 5px;
      }
    }

    .footer__logo__container{
      margin: 35px auto 15px;
      text-align: center;
      justify-content: center;
    }

    .footer__name,
    .footer__phone {
      margin-top: 0;
    }

    .footer__address {
      margin-top: 8px;
      span{
        display: inline-block;
        min-width: 120px;
      }
    }

    .footer__column-left,
    .footer__column-right {
      padding-top: 48px;
    }

    @include breakpoint(large) {

      .footer__column-left,
      .footer__column-right {
        margin-right: 36px;
      }

    }

  }

}