.header {
  width: 100%;
  height: 88px;
  // box-shadow: 0 8px 24px 0 rgba($almost-black, 0.1);
  background-color: transparent;
  position: absolute;
  top: 0;
  z-index: 2;

  &__container {
    width: 100%;
    max-width: 1208px;
    padding-left: 16px;
    padding-right: 16px;
    margin-left: auto;
    margin-right: auto;

    display: flex;
    justify-content: space-between;
  }

  $logo_min_width: 200px;

  &__logo {
    flex-shrink: 0;
    display: flex;
    width: $logo_min_width;
    
    img {
      margin: auto auto auto 0;
      max-width: $logo_min_width;
      max-height: 110px;
    }

    &-card-container {
      display: table;
      box-shadow: 0 8px 24px 0 rgba($almost-black, 0.1);
      background-color: $white;
      padding: 10px;
      box-sizing: content-box;
    }

    &-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      box-shadow: none;
      min-height: 100%;
      font-weight: 600;
      img {
        max-height: 100%;
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }

  }

  &__nav-group {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__nav-link {
    font-weight: 600;
    padding: 0 16px;

    &:last-child {
      padding-right: 0;
    }

    a {
      white-space: nowrap;
      color: $metal-black;
      transition: color .2s;
      cursor: pointer;

      &:hover {
        color: rgba($metal-black, .7);
      }

      &.is-active {}
    }
  }

  &__nav-more {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0 16px;
    position: relative;

    &:last-child {
      padding-right: 0;
    }

    a,
    span {
      font-weight: 600;
      white-space: nowrap;
      color: $metal-black;
      transition: color .2s;
      cursor: pointer;
      outline: none;

      &:hover {
        color: rgba($metal-black, .7);
      }
    }

    .icon-down-open-mini:before {
      vertical-align: middle;
    }

    .header__nav-more-dropdown {
      position: absolute;
      z-index: 1;
      right: 0;
      min-width: 150px;
      overflow: hidden;
      max-height: 0;

      background-color: $white;
      box-shadow: 0 0 3px rgba($metal-black, .7);

      // Base State
      $more-menu-transition-duration: .2s;

      top: calc(100% - 20px);
      opacity: 0;
      overflow: hidden;
      transition:
        padding 0s $more-menu-transition-duration,
        max-height 0s $more-menu-transition-duration,
        opacity $more-menu-transition-duration ease-in 0.01s,
        top $more-menu-transition-duration ease-in 0.01s;


      &.active {
        transition:
          padding 0s,
          max-height 0s,
          opacity $more-menu-transition-duration ease-in 0.01s,
          top $more-menu-transition-duration ease-in 0.01s;

        padding: 16px;
        display: initial;
        opacity: 1;
        top: 100%;
        overflow: visible;
        max-height: 1000px;
      }

      .more__line {
        width: 50px;
        height: 1px;
      }

      .more__nav-link {
        margin-top: 8px;
      }
    }
  }

  &__nav-button {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-left: 16px;

    button: {
      font-size: 1em;
    }
  }

  &__menu-button {
    display: flex;
    justify-content: center;
    flex-direction: column;
    opacity: 1;
    transition: .2s;
    cursor: pointer;
    z-index: 1;

    &:hover {
      opacity: .8;
    }
  }

  .hamburger {
    outline: none;
    cursor: pointer;

    div {
      height: 5px;
      width: 33px;
      background: $almost-black;
      margin: 0 0 3px;
      border-radius: 3px;
      transition: .2s;
      transform-origin: center center;
    }


    &.active {

      .hamburger-bun {
        margin: 0;
        -webkit-transform-origin: center center;
        -moz-transform-origin: center center;
        -o-transform-origin: center center;
        transform-origin: center center;

        &:first-child {
          transform: rotate(45deg);
        }

        &:last-child {
          transform: rotate(-45deg);
          margin-top: -6px;
        }
      }

      .hamburger-patty {
        display: none;
      }
    }
  }


  /*
   * Modifiers
   */

  &.header--mobile-only {

    // Hide the nav group
    .header__nav-group {
      display: none;
    }

    // Display the hamburger menu
    .header__menu-button {
      display: flex !important;
    }
  }

  &--black {
    background-color: $almost-black;

    .header__logo-card-container {
      background-color: $almost-black;
    }

    .header__nav-more-dropdown {
      background-color: $almost-black;
    }
  }

  &--white-opaque {
    background-color: rgba($white, .9);

    .header__logo-card-container {
      background-color: rgba($white, .9);
    }

    .header__nav-more-dropdown {
      background-color: rgba($white, .9)
    }
  }

  &--black-opaque {
    background-color: rgba($almost-black, .9);

    .header__logo-card-container {
      background-color: rgba($almost-black, .9);
    }

    .header__nav-more-dropdown {
      background-color: rgba($almost-black, .9);
    }
  }

  &--black,
  &--black-opaque {
    .hamburger div {
      background-color: $white;
    }

    .header__logo-container {
      color: $white;
    }

    .header__nav-link,
    .header__nav-more {

      a,
      button,
      span {
        color: $white;

        &:hover {
          color: rgba($white, .7);
        }
      }

      .more__line {
        background-color: $white;
      }
    }
  }

}
.reveal{
  padding: 0;
  border: 0;
  &:focus{outline: 0}
  .close-button{
    color: white;
    font-size: 1.7em;
  }
}
.reveal-title{
  // background:
  color: #fff;
  margin: 0!important;
  font-size: 24px;
  padding: 28px;

  @include breakpoint(small down) {
    padding: 18px;
  }
}
.reveal-content{
  padding: 28px;
  padding-bottom: 38px;
  padding-top: 20px;
  background: #fff;
  @include breakpoint(small down) {
    padding: 18px;
  }
}
#flashMessage {
  width: 100%;
  text-align: center;
  padding: 20px 10px;
  background: #ff5253;
  color: #FFF;
  font-weight: 700;
  box-shadow: inset 2px 3px 5px 0 rgba($almost-black, 0.2);
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  right: 0;

  &.success {
    background: #5da423;
  }
}
