.gallery {
  background-image: url("../img/gallery-background.png");
  background-size: cover;
  background-position: center;
  padding-left: 0;
  padding-right: 0;
  padding-top: 64px;

  .gallery__carousel {
    position: relative;
    height: 70vh;

    margin-top: 64px;

    .gallery__carousel-arrow-left {
      left: 36px;

      &::after {
        content: '\f001';
      }
    }

    .gallery__carousel-arrow-right {
      right: 36px;

      &::after {
        content: '\f002';
      }
    }

    .gallery__carousel-arrow-left,
    .gallery__carousel-arrow-right {
      position: absolute;
      top: 50%;
      cursor: pointer;
      z-index: 1;

      &::after {
        font-family: flexslider-icon;
        font-size: 42px;
        display: inline-block;
        color: rgba(255, 255, 255, .8);
        text-shadow: 1px 1px 0 rgba(0, 0, 0, .3);
      }
    }


    .gallery__carousel-images-slider {
      width: 100%;
      height: 100%;
      overflow: hidden;
    }

    .gallery__carousel-images {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      z-index: 0;

      left: 0;
      transition: left 0.3s ease-in-out;
    }


    .gallery__carousel-image {
      width: 100%;
      height: 100%;
      background-image: url("https://placeimg.com/1296/600/arch");
      background-position: center;
      background-size: cover;
      flex-shrink: 0;
      display: block;
    }
  }

}

.gallery__grid {
  display: flex;
  justify-content: flex-start;
  height: 70vh;

  margin-top: 64px;

  .photo-container {
    position: relative;
    background-position: center;
    background-size: cover;

    .overlay {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: rgba($almost-black, .8);
      opacity: 0;
      transition: opacity .2s;
      cursor: pointer;

      .icon-zoom:before {
        content: "\69";
      }

      i {
        font-size: 50px;
        font-style: normal;
        position: absolute;
        left: 50%;
        top: 50%;
        margin-top: -25px;
        margin-left: -25px;
        color: #FFF;
        transition: transform .2s;
        transform: scale(2);
      }

      &:hover,
      &.active {
        opacity: 1;

        i {
          transform: scale(1);
        }

        &.solid {
          background: $almost-black;
        }
      }
    }
  }

  @include breakpoint(medium down) {
    flex-direction: column;
  }
}

.gallery__column {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  @include breakpoint(large) {

    &.left {
      width: 40%;
    }

    &.middle {
      width: 40%;
    }

    &.right {
      width: 20%;
    }

  }

  @include breakpoint(medium down) {

    &.left {
      flex-direction: row;
    }

    &.middle {
      flex-direction: column;
    }

    &.right {
      flex-direction: row;
    }
  }
}



.gallery {

  .photo1,
  .photo2,
  .photo3,
  .photo4,
  .photo5 {
    background-position: center;
    background-size: cover;
    margin: 0;
  }

  .remaining-photos-count {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    color: white;
  }

  @include breakpoint(large) {
    .photo1 {
      margin-right: 8px;
      margin-bottom: 8px;
      height: 50%;
      width: calc(100% - 8px);
    }

    .photo2 {
      margin-right: 8px;
      margin-top: 8px;
      height: 50%;
      width: calc(100% - 8px);
    }

    .photo3 {
      margin-right: 8px;
      margin-left: 8px;
      height: 100%;
      width: calc(100% - 16px);
    }

    .photo4 {
      margin-left: 8px;
      margin-bottom: 8px;
      height: 50%;
      width: calc(100% - 8px);
    }

    .photo5 {
      margin-left: 8px;
      margin-top: 8px;
      height: 50%;
      width: calc(100% - 8px);
    }
  }

  @include breakpoint(medium down) {
    .photo1 {
      margin-right: 8px;
      margin-bottom: 8px;
      width: 50%;
    }

    .photo2 {
      margin-left: 8px;
      margin-bottom: 8px;
      width: 50%;
    }

    .photo3 {
      margin-bottom: 8px;
      margin-top: 8px;
      width: 100%;
      height: 100%;
    }

    .photo4 {
      margin-right: 8px;
      margin-top: 8px;
      width: 50%;
    }

    .photo5 {
      margin-left: 8px;
      margin-top: 8px;
      width: 50%;
    }

  }
}