.parcels {
  // background-color: #f0f0f0;
  @include padding-top(40px, 32px);
  // @include padding-bottom(32px, 32px);

  .parcels__sub-title {
    margin-top: 16px;
  }

  .parcels__text {
    @include margin-top(32px, 24px);
  }

  .parcels__carousel {
    position: relative;
  }

  .parcels__carousel-arrow-left {
    left: 40px;

    &::after {
      content: '\f001';
    }
  }

  .parcels__carousel-arrow-right {
    right: 40px;

    &::after {
      content: '\f002';
    }
  }

  .parcels__carousel-arrow-left,
  .parcels__carousel-arrow-right {
    position: absolute;
    top: 250px;
    cursor: pointer;
    z-index: 1;

    @include breakpoint(medium down) {
      display: none;
    }

    &::after {
      font-family: flexslider-icon;
      font-size: 32px;
      display: inline-block;
      color: rgba(0, 0, 0, .8);
      text-shadow: 1px 1px 0 rgba(255, 255, 255, .3);
    }
  }

  .parcels__container-slider {
    overflow: hidden;
    // padding: 6px 8px 10px 8px;
  }

  .parcels__container {
    display: flex;
    position: relative;

    left: 0;
    transition: left 0.3s ease-in-out;
  }

  .parcel-box__container {
    display: flex;
    background: $white;
    justify-content: space-between;
    flex-direction: row;
    padding: 0;
    border-radius: 5px;
    flex-shrink: 0;
    margin-left: 16px;
    flex-wrap: wrap;

    &:first-child {
      margin-left: 0;
    }

    margin-top: 32px;

    @include breakpoint(medium down) {
      // flex-direction: column-reverse;
    }
  }

  .parcel-box__content {
    padding: 48px;
    width: 100%;
    flex-basis: 100%;
    order: 2;

    @include breakpoint(large down) {
      padding: 36px;
    }
    @include breakpoint(medium down) {
      width: inherit;
      padding: 24px;
    }
  }

  .parcel-box__name {
    font-size: 20px;
    font-weight: 500;
  }

  .parcel-box__attributes {
    margin-top: 32px;
    display: flex;
    flex-wrap: wrap;

    @include breakpoint(medium down) {
      justify-content: space-between;
    }
  }

  .parcel-box__attribute {
    height: 72px;
    padding-right: 8px;

    @include breakpoint(large) {
      width: 20%;
    }

    @include breakpoint(medium) {
      width: 33.3%;
    }

    @include breakpoint(small) {
      width: 50%;
    }


    &-name {
      font-size: 12px;
      font-weight: 600;
      color: $persian-blue;
    }

    &-value {
      color: $metal-black;
    }
  }

  .parcel-box__map {
    background-image: url('https://www.cpvmfg.com/app/uploads/2018/04/map-placeholder.jpg');
    background-position: center;
    background-size: cover;
    border-radius: 5px 5px 0 0;
    width: 100%;
    flex-basis: 100%;
    order: 1;

    @include breakpoint(large) {
      min-height: 500px;
    }

    @include breakpoint(medium down) {
      border-radius: 5px 5px 0 0;
      width: inherit;
      min-height: 300px;
    }
  }

  .carousel__indicators {
    top: 8px;

    @include breakpoint(large) {
      display: none;
    }
  }

}