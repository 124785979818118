.site{
	&__wrapper{
		margin-top: 40px;
		padding-bottom: 40px;
		background-color: #f0f0f0;
		@include breakpoint(medium down) {
			margin-top: 0;
		}
	}
	&__content{
		padding-top: 24px;
		padding-left: 12px;
		@include breakpoint(medium down) {
			padding-left: 0;
      	} 
		// padding-bottom: 40px;
	}
	&__sidebar{
		padding-top: 24px;
		padding-right: 12px;
		padding-bottom: 34px;
		@include breakpoint(medium down) {
			padding-bottom: 0;
			padding-top: 38px;
			padding-right: 0;
		}
		&>:first-child:not(.docportal){
			@include breakpoint(medium up) {
				margin-top: -40px;
			}
		}
	}

}